<template>
    <main @click="flow()" class="base">
        <div class="mezzintro">
            <div class="mezz-block">
                <svg style="z-index:1;position:absolute;" width="512px" height="512px" viewBox="0 0 3050 2950">
                                                                                                                    <path id="ourtext" d="M682.74,372.115C540.14,372.115 424.367,487.888 424.367,630.488L424.367,2297.53C424.367,2440.13 540.14,2555.9 682.74,2555.9L2349.78,2555.9C2492.38,2555.9 2608.15,2440.13 2608.15,2297.53L2608.15,630.488C2608.15,487.888 2492.38,372.115 2349.78,372.115L690.042,372.115" style="fill:none;stroke:none;stroke-width:0.91px;"/>
                                                                                                                    <text y="40" font-size="30" fill="pink" font-family="Permanent Marker, cursive">
                                                                                                                        <textPath id="text-path" class="text" href="#ourtext" :startOffset="textPosStr"> MEZZ lets you buy the impossible</textPath>    
                                                                                                                    </text>  <text y="40" font-size="30" fill="#00aaff" font-family="Permanent Marker, cursive">
                                                                                                                        <textPath id="text-path" class="text" href="#ourtext" :startOffset="textPosStr2"> MEZZ lets you buy the impossible </textPath>    
                                                                                                                    </text><text y="40" font-size="30" fill="orange" font-family="Permanent Marker, cursive">
                                                                                                                        <textPath id="text-path" class="text" href="#ourtext" :startOffset="textPosStr3"> MEZZ lets you buy the impossible </textPath>    
                                                                                                                    </text>
                                                                                                                    </svg>
                <div class="mezz-container">
    
    
                </div>
                <div class="text-container">
                    <svg id="cornertl" width="100px" height="100px" viewBox="0 0 1158 1158" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;position:absolute;top:8rem;left:8rem;z-index:10;">
                                                                                                        <g transform="matrix(1,0,0,1,-556.862,-552.764)">
                                                                                                            <g transform="matrix(1,0,0,1,-134.173,-109.457)">
                                                                                                                <path fill="#ffaaff" d="M1648.98,861.727C1677.58,833.127 1686.14,790.115 1670.66,752.748C1655.18,715.38 1618.72,691.016 1578.27,691.016C1340.88,691.016 990.095,691.016 819.831,691.016C793.309,691.016 767.874,701.552 749.12,720.306C730.366,739.059 719.831,764.495 719.831,791.016L719.831,1549.46C719.831,1589.91 744.195,1626.37 781.562,1641.85C818.93,1657.33 861.942,1648.77 890.541,1620.17C1097.02,1413.69 1442.51,1068.2 1648.98,861.727Z" style="stroke:black;stroke-width:57.59px;"/>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </svg>
                    <svg id="cornerbl" width="100px" height="100px" viewBox="0 0 1158 1158" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;transform:rotate(180deg);position:absolute;bottom:8rem;right:8rem;z-index:10;   ">
                                                                                                        <g transform="matrix(1,0,0,1,-556.862,-552.764)">
                                                                                                            <g transform="matrix(1,0,0,1,-134.173,-109.457)">
                                                                                                                <path fill="#00aaff" d="M1648.98,861.727C1677.58,833.127 1686.14,790.115 1670.66,752.748C1655.18,715.38 1618.72,691.016 1578.27,691.016C1340.88,691.016 990.095,691.016 819.831,691.016C793.309,691.016 767.874,701.552 749.12,720.306C730.366,739.059 719.831,764.495 719.831,791.016L719.831,1549.46C719.831,1589.91 744.195,1626.37 781.562,1641.85C818.93,1657.33 861.942,1648.77 890.541,1620.17C1097.02,1413.69 1442.51,1068.2 1648.98,861.727Z" style="stroke:black;stroke-width:57.59px;    background-image: url(/mezz.gif);"/>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </svg>
                    <h1>10 DAYS</h1>
                </div>
            </div>
    
        </div>
    </main>
</template>

<script>
import useClipboard from 'vue-clipboard3'
export default ({
    setup() {
        const { toClipboard } = useClipboard()
        const copy = async () => {
            try {
                await toClipboard('0xC4c346eDc55504574cCEB00AA1091d22404A4bC3')
                console.log('Copied to clipboard')
            } catch (e) {
                console.error(e)
            }
        }

        return { copy }
    },
    data() {
        return {
            textPos: -500,
            textPos2: 500,
            textPos3: 0,
            textPosStr: '',
            textPosStr2: '',
            textPosStr3: '',

        }
    },
    mounted: function() {
        window.setInterval(() => {
            this.flow()
        }, 10)

    },
    methods: {
        flow() {
            this.textPos++
                this.textPos2++
                this.textPos3++
                this.textPosStr = String(this.textPos / 10 + "%")
            this.textPosStr2 = String(this.textPos2 / 10 + "%")
            this.textPosStr3 = String(this.textPos3 / 10 + "%")
            if (this.textPos > 1000) {
                this.textPos = -500;
            }
            if (this.textPos2 > 1000) {
                this.textPos2 = -500;
            }
            if (this.textPos3 > 1000) {
                this.textPos3 = -500;
            }
        }

    },
    computed: {

    },
})
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Mochiy+Pop+One&family=Permanent+Marker&display=swap');
.text {
    font-size: 10rem;
}

.mezz-container {
    width: 512px;
    border-radius: 7rem;
    height: 512px;
    background-image: url(/mezz.gif);
    position: absolute;
    top: 0px;
    z-index: 0;
    &:after {
        content: '';
        width: 320px;
        height: 320px;
        backdrop-filter: blur(100px)brightness(2.5);
        border: 60px solid rgb(0, 0, 0);
        position: absolute;
        border-radius: 5rem;
        margin: 36px;
        box-shadow: inset 0px 0px 50px black;
    }
}

.mezz-block {
    width: 512px;
    height: 512px;
    &:hover {
        .text-container {
            transform: rotate(45deg)scale(0.8);
        }
    }
}

.mezzintro {
    margin: 2rem;
    width: 512px;
    height: 512px;
    position: relative;
    top: 0px;
    margin: auto;
    &:before {
        content: '';
        width: 512px;
        height: 512px;
        left: 0px;
        border-radius: 7rem;
        background-image: url(/mezz.gif);
        position: absolute;
        top: 0px;
        filter: blur(50px);
        z-index: 0;
    }
}

.base {
    display: flex;
    flex-flow: column;
    background: rgb(0, 0, 0);
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.text-container {
    width: 512px;
    height: 512px;
    position: absolute;
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
    transition: all 0.5s ease;
    h1 {
        text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.2);
        transform: rotate(-45deg);
        font-size: 5rem;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: url(/mezz.gif);
        color: transparent !important;
    }
}

#text-path {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    background-image: url(/mezz.gif) !important;
    color: transparent !important;
}

@keyframes raster {
    0% {
        startOffset: 0%;
    }
    100% {
        startOffset: 100%;
    }
}

@media screen and (max-width:600px) {
    .mezzintro {
        transform: scale(0.8);
    }
}
</style>